import { render, staticRenderFns } from "./SchoolsAdd.vue?vue&type=template&id=779cf151&scoped=true&"
import script from "./SchoolsAdd.vue?vue&type=script&lang=js&"
export * from "./SchoolsAdd.vue?vue&type=script&lang=js&"
import style0 from "./SchoolsAdd.vue?vue&type=style&index=0&id=779cf151&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "779cf151",
  null
  
)

export default component.exports